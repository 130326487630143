import React from 'react'
import Link from 'components/GatsbyLinkWrapper/index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'

import { Section } from './style'

const PJAccount = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section id='pj-account' className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 d-flex justify-content-center'> 
            <ImageWebp
              arrayNumbers={[ 261, 261, 374, 454 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/negocio-dobra2/image.webp'
              altDescription='Empreendedor abrindo sua conta Inter Empresas através do seu smartphone'
            />
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2 mt-3'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 fw-500 mb-3'>
              Uma conta PJ pensada para o empreendedor
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400'>
              Solução 100% digital e gratuita que atende todas as necessidades da sua empresa, seja PJ, MEI, <Link className='underline text-grayscale--400' to='/empresas/conta-digital/conta-condominio/
'>Condomínio</Link>. Uma conta voltada para empresas de todos os tamanhos, ideal para seu tipo de negócio.
            </p>
            <Link
              title='Conhecer Conta PJ'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-3'
              to='/empresas/conta-digital/pessoa-juridica/'
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  element_name: 'Conhecer Conta PJ',
                  section_name: 'Uma conta PJ pensada para o empreendedor',
                  redirect_url: `${window.location.origin}/empresas/conta-digital/pessoa-juridica/`
                })
              }}
            >
              Conhecer Conta PJ
            </Link>
            <Link
              title='Conhecer Conta MEI'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn--outline btn--orange mt-2'
              to='/empresas/conta-digital/mei/'
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  element_name: 'Conhecer Conta MEI',
                  section_name: 'Uma conta PJ pensada para o empreendedor',
                  redirect_url: `${window.location.origin}/empresas/conta-digital/mei/`
                })
              }}
            >
              Conhecer Conta MEI
            </Link>
         </div>
        </div>
      </div>
    </Section>
  )
}

export default PJAccount
