import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { sand } from 'src/styles/newColors'

export const Section = styled.section`
  background-color: ${sand};
    
  @media (min-width: ${breakpoints.md}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/negocio-dobra7/image.webp');
    background-size: contain;
    background-position: 410px 0;
    background-repeat: no-repeat;
    height: 358px;
  }
  
  @media (min-width: ${breakpoints.lg}){
    background-position: top right;
    height: 404px;
  }
  @media (min-width: ${breakpoints.xl}){
    min-height: 576px;
  }

  a {
    @media (min-width: ${breakpoints.md}){
      max-width: 336px;
    }
    @media (min-width: ${breakpoints.lg}){
      max-width: 376px;
    }
    @media (min-width: ${breakpoints.xl}){
      max-width: 454px;
    }
  }
`
