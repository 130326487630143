import React from 'react'
import Link from 'components/GatsbyLinkWrapper/index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'
 
import { Section } from './style'

const CreditCard = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 bg-gray'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2 order-md-last d-flex justify-content-center justify-content-lg-end'>
            <ImageWebp
              arrayNumbers={[ 303, 303, 395, 559 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/pro-seu-negocio-dobra3-1440/image.webp'
              altDescription='Ilustração de um cartão Inter Empresas ao lado de um smartphone com o Super App Inter Empresas em destaque.'
            />
          </div>
          <div className='col-12 col-md-6 col-lg-5 mt-3'>
            <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 fw-500 mb-3'>
              Cartão de Crédito com programa de pontos
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400'>
              Aproveite o melhor do nosso Cartão Empresarial Microbusiness com Inter Loop, nosso programa de pontos exclusivo para empresas. Use os pontos para ganhar dinheiro de volta direto na conta, desconto na fatura ou cashback extra no shopping do Inter.  
            </p>
            <Link
              title='Conhecer o Cartão'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-2'
              to='/empresas/cartao-de-credito-empresarial/'
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_03',
                  element_action: 'click button',
                  element_name: 'Conhecer o Cartão',
                  section_name: 'Cartão de Crédito com programa de pontos',
                  redirect_url: `${window.location.origin}/empresas/cartao-de-credito-empresarial/`
                })
              }}
            >
              Conhecer o Cartão
            </Link>
            <Link
              title='Saber mais sobre o Inter Loop'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn--outline btn--orange mt-2'
              to='/empresas/programa-de-pontos-para-empresas/'
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_03',
                  element_action: 'click button',
                  element_name: 'Saber mais sobre o Inter Loop',
                  section_name: 'Cartão de Crédito com programa de pontos',
                  redirect_url: `${window.location.origin}/empresas/programa-de-pontos-para-empresas/`
                })
              }}
            >
              Saber mais sobre o Inter Loop
            </Link>
         </div>
        </div>
      </div>
    </Section>
  )
}

export default CreditCard
