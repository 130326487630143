import React from 'react'
import Link from 'components/GatsbyLinkWrapper/index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ExclusiveRelationshipJSON from '../../assets/data/exclusiveRelationship.json'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Card } from './style'

type ExclusiveRelationshipProps = {
  title: string;
  description: string;
  cta: string;
  url: string;
  urlBlog: string;
}

const ExclusiveRelationship = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='py-5 d-flex align-items-center' role='img' aria-label='Cliente Inter Empresas sorridente enquanto pesquisa sobre as soluções de crédito disponíveis no Super App'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12'>
            <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 fw-500 mb-3'>
              Relacionamento Exclusivo: seu negócio vai ainda mais longe!
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400'>
              Sua empresa pode ter vantagens exclusivas e acesso a um atendimento personalizado ágil, com um Advisor dedicado 
            </p>
         </div>
        </div>
        <div className='row mt-2'>
          {ExclusiveRelationshipJSON.map((item: ExclusiveRelationshipProps) => (
            <div className='col-12 col-lg-6 mb-3'>
              <Card className='bg-gray rounded-4 px-4 py-4 px-md-5 py-3 py-md-5 d-flex flex-column justify-content-between'>
                <div key={item.title}>
                  <div><h3 className='fs-20 lh-24 text-grayscale--500 fw-500'>{item.title}</h3></div>
                  <div><p className='fs-16 lh-20 text-grayscale--400 fw-400'>{item.description}</p></div>
                </div>
                <div className='d-flex justify-content-end align-items-center'>
                  <Link
                    to={item.urlBlog ? item.urlBlog : item.url}
                    title={item.title}
                    className='fs-16 fs-md-18 text-right text-primary--500 fw-700 mr-1'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_09',
                        element_action: 'click button',
                        element_name: `Saiba mais - ${item.title}`,
                        section_name: 'Relacionamento Exclusivo: seu negócio vai ainda mais longe!',
                        redirect_url: `${item.urlBlog ? item.urlBlog : window.location.origin}${item.url}`
                      })
                    }}
                  >
                      Saiba mais
                  </Link>
                  <OrangeIcon size='MD' color='#FF7A00' icon='arrow-right' />
                </div>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ExclusiveRelationship
