import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import {orange } from 'src/styles/newColors'

export const Section = styled.section`
  background-color: ${orange.dark};
  
  @media (min-width: ${breakpoints.md}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/negocio-dobra5/image.webp');
    background-size: contain;
    background-position: top center;
    background-size: 960px;
    background-repeat: no-repeat;
    height: 403px;
  }

  @media (min-width: ${breakpoints.lg}){
    height: 478px;
    background-size: cover;
  }

  @media (min-width: ${breakpoints.xl}){
    height: 610px;
    background-size: cover;
  }

  @media (min-width: ${breakpoints.xxxl}){
    height: 800px;
    background-size: cover;
  }

  a {
    @media (min-width: ${breakpoints.md}){
      max-width: 100%;
    }
  }
`
