import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange } from 'src/styles/newColors'
import { gray } from 'src/styles/colors'

export const Section = styled.section`
  a {
    @media (min-width: ${breakpoints.lg}){
      max-width: 376px;
    }
    @media (min-width: ${breakpoints.xl}){
      max-width: 454px;
    }
  }
  .underline {
    text-decoration: underline;
  }
}
`

export const Management = styled.div`
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/negocio-dobra8-360/image.webp');
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 210px;
  background-color: ${orange.dark};
  height: 312px;

  @media (min-width: ${breakpoints.md}){
    height: 557px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/negocio-dobra8-web/image.webp');
    background-position: -60px 180px;
    background-size: 270px;
  }

  @media (min-width: ${breakpoints.lg}){
    height: 683px;
    background-position: 0px 230px;
    background-size: 365px;
  }

  @media (min-width: ${breakpoints.xl}){
    height: 758px;
    background-position: 0px 210px;
    background-size: 389px;
  }
  `

export const SimplifiedPayroll = styled.div`
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/negocio-dobra8-2/image.webp');
  height: 312px;
  background-position: 19px 120px;
  background-repeat: no-repeat;
  background-size: 216px;
  background-color: ${gray[400]};

  @media (min-width: ${breakpoints.md}){
    height: 241px;
    background-position: 39px 106px;
    background-size: 156px;
  }

  @media (min-width: ${breakpoints.lg}){
    height: 277px;
    background-position: 52px 109px;
    background-size: 194px;
  }

  @media (min-width: ${breakpoints.xl}){
    height: 338px;
    background-size: 261px;
  }
`

export const Api = styled.div`
  background-color: ${gray[400]};
  height: 342px;

  @media (min-width: ${breakpoints.md}){
    height: 302px;
    background-size: 74%;
    background-position: 45px 158px;
  }

  @media (min-width: ${breakpoints.lg}){
    height: 391px;
    background-position: 68px 168px;
  }

  @media (min-width: ${breakpoints.xl}){
    height: 405px;
    background-size: 400px;
  }
`
