import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import { Wrapper } from './style'

import pageContext from './pageContext.json'
import Hero from './_sections/hero/_index'
import PJAccount from './_sections/pj-account/_index'
import CreditCard from './_sections/credit-card/_index'
import GraniteMachine from './_sections/granito-machine/_index'
import CreditSolutions from './_sections/credit-solutions/_index'
import InvestmentsForBusiness from './_sections/investments-for-business/_index'
import GlobalAccountBusiness from './_sections/global-account-business/_index'
import Payroll from './_sections/payroll/_index'
import ExclusiveRelationship from './_sections/exclusive-relationship/_index'
import BusinessInsurance from './_sections/business-insurance/_index'

const ForYourBusiness = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <PJAccount />
        <CreditCard />
        <GraniteMachine />
        <CreditSolutions />
        <InvestmentsForBusiness />
        <GlobalAccountBusiness />
        <Payroll />
        <ExclusiveRelationship />
        <BusinessInsurance />
      </Layout>
    </Wrapper>
  )
}

export default ForYourBusiness
