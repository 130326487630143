import React from 'react'
import ScrollTo from 'src/components/ScrollTo'

import { Section } from './style'

const Hero = () => {
  return (
    <Section className='py-5 d-flex' role='img' aria-label='Cliente Inter Empresas sorrindo enquanto segura seu cartão PJ e acessa seu notebook.'>
      <div className='container d-flex align-items-center'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5'>
            <h1 className='fs-24 fs-md-28 fs-lg-40 fs-xl-56 lh-28 lh-md-33 lh-lg-45 lh-xl-65 text-white fw-500 mb-3'>
              Tudo sobre o Inter Empresas
            </h1>
            <p className='fs-20 fs-lg-24 fl-xl-28 lh-24 lh-lg-28 lh-xl-22 text-white fw-500 subtitle'>A solução que leva seu negócio além</p>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-white fw-400'>Conta empresarial gratuita, sem burocracia e com vantagens exclusivas, como pix ilimitado, cartão débito/crédito sem anuidade e com programa de pontos, boletos gratuitos todo mês, folha de pagamento, investimentos, e muito mais!</p>
            <ScrollTo
              styles='fs-14 fw-600 btn btn--lg btn-orange--extra text-none rounded-3 mt-3 mt-md-0'
              to='#pj-account'
              section='dobra_01'
              sectionName='Tudo sobre o Inter Empresas'
              elementName='Saber mais'
              title='Saber mais'
            >
              Saber mais
            </ScrollTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
