import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange } from 'src/styles/newColors'

export const Section = styled.section`
  background-color: ${orange.dark};

  h1 {
    letter-spacing: 0px;
  }
  
  .subtitle {
    font-family: 'Citrina';
  }
  
  @media (min-width: ${breakpoints.md}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/pro-seu-negocio-dobra1-1440/image.webp');
    height: 364px;
    background-position: 408px 0;
    background-repeat: no-repeat;
    background-size: 490px;
  }

  @media (min-width: ${breakpoints.lg}){
    height: 494px;
    background-position: 468px 0px;
    background-size: 640px;

    a {
      max-width: 376px;
    }
  }

  @media (min-width: ${breakpoints.xl}){
    height: 573px;
    background-position: top right;
    background-size: contain;

    a {
      max-width: 454px;
    }
  }

  @media (min-width: ${breakpoints.xxxl}){
    background-position: top right;
    background-size: 786px;
  }
`

export const Button = styled.div`
  border: 1px solid #FFFFFF;

  &:focus, &:hover {
    outline: none;
    text-decoration: none;
    color: #fff;
  }

  float: left;
  clear: both;

  @media (min-width: ${breakpoints.md}){
    width: 218px;
  }

  @media (min-width: ${breakpoints.lg}){
    width: 296px;
  }

  @media (min-width: ${breakpoints.xl}){
    width: 264px;
  }
`
