import React from 'react'
import Link from 'components/GatsbyLinkWrapper/index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'

import { Section } from './style'

const InvestmentsForBusiness = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 bg-gray'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 d-flex justify-content-center mb-3'>
            <ImageWebp
              arrayNumbers={[ 297, 334, 407, 515 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/pro-seu-negocio-dobra6-1440/image.webp'
              altDescription='Ilustração de um cartão Inter Empresas ao lado de um smartphone com o Super App Inter Empresas em destaque'
            />
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2'>
            <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 fw-500 mb-3'>
              Investimentos para empresas: valorize ainda mais o dinheiro do seu negócio!
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400'>
              No Super App você tem acesso a uma plataforma completa de investimentos, perfeita para sua empresa se desenvolver, rentabilizar o capital e diversificar o faturamento. Aproveite diversas opções de investimentos, fundos exclusivos, cashback e muito mais!
            </p>
            <Link
              title='Investir agora'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-3 mt-md-2'
              to='/empresas/investimento-empresarial/'
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  element_action: 'click button',
                  element_name: 'Saber mais',
                  section_name: 'Investimentos para empresas: valorize ainda mais o dinheiro do seu negócio!',
                  redirect_url: `${window.location.origin}/empresas/investimento-empresarial/`,
                })
              }}
            >
              Saber mais
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default InvestmentsForBusiness
